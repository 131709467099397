<template>
  <div id="home">
    <img src="../assets/image/logo.png" />
    <div class="search">
      <el-input 
        v-model="value" 
        placeholder="请输入标准号 / 标准名称" 
        clearable 
        @keydown="keydownClick"/>
      <el-button type="primary" @click="searchClick">检索</el-button>
    </div>
    <p>备注：支持以标准名称或者标准号检索，支持模糊检索</p>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from "vue-router";
export default {
  name: 'Home',
  setup(props, content) {
    let value = ref('')
    const router = useRouter();
    function keydownClick(e) {
      if(e.keyCode == 13) {
        searchClick()
      }
    }
    function searchClick() {
      // if(!this.value || !this.value.trim()) {
      //   return this.$message.error('请输入搜索内容')
      // }else {
        router.push(`/searchList?search=${ value.value }`)
      // }
    }
    return {
      value,
      keydownClick,
      searchClick
    }
  },
  // data() {
  //   return {
  //     value: ''
  //   }
  // },
  // methods: {
  //   keydownClick(e) {
  //     if(e.keyCode == 13) {
  //       this.searchClick()
  //     }
  //   },
  //   searchClick() {
  //     // if(!this.value || !this.value.trim()) {
  //     //   return this.$message.error('请输入搜索内容')
  //     // }else {
  //       this.$router.push(`/searchList?search=${this.value}`)
  //     // }
  //   }
  // }
}
</script>
<style lang='scss'>
  #home {
    padding: 180px 0 0;
    text-align: center;

    & > img {
      width: 378px;
      height: 100px;
    }

    .search {
      margin: 40px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-input {
        width: 500px;
        height: 40px;
        border-radius: 4px;
      }

      .el-button {
        margin-left: 6px;
        width: 80px;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
      }
    }

    & > p {
      font-size: 14px;
      color: #909399;
      line-height: 20px;
    }
  }
</style>
